.radio-marquee-container {
  overflow: hidden;
  white-space: nowrap;
}

.radio-marquee-text {
  display: inline-block;
  animation: radio-marquee 6s linear infinite;
}

@keyframes radio-marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
